import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Template3SliderReview } from "@tradesorg/component-library-waas-templates.template-3-slider-review";
import { SectionHeadingComponent  } from '@tradesorg/component-library-waas-templates.template-3-section-heading';
import ImageReview from '@assets/images/template_3_review.png';
import "./index.scss";

const Review = ({ data }) => {
    const { slogan, heading, feedbacks } = data;

    const reviewMapper = (feedbacks) => {
        return feedbacks.map(item => ({
                content: item.review,
                reviewBy: {
                    name: item.fullName,
                    title: item.title,
                    avatarUrl: item.img[0].url,
                }
            })
        )
    }

    const reviews = reviewMapper(feedbacks)

    return (
        <div id="testimonials" className="reviewComponentWrapper md-1:grid grid-cols-1 md-1:grid-cols-3 lg:grid-cols-2 max-h-866px">
            <div
                className=" md-1:col-span-2 lg:col-span-1 flex flex-wrap pl-5 md-1:pl-10 xl:pl-98px 2xl:pl-390px flex-col justify-center items-center xl:items-start md-1:max-w-lg md-1:max-w-full mx-auto pt-51px">
                <div className={'review-header'}>
                    <SectionHeadingComponent
                        sloganText={slogan}
                        heading={ ReactHtmlParser(heading) }
                        centered={true}
                        className={{
                            'heading': 'mt-14px md-1:mt-19px mb-0 md-1:mb-10px xs:font-24px md-1:font-30px',
                            'slogan': 'xs:font-14px md-1:font-16px',
                            'wrapper': 'xl:items-start',
                        }}
                    />
                </div>

                <Template3SliderReview items={reviews} />
            </div>

            <div className="img-about-us col-span-1 -mx-5 md-1:mx-0">
                <img
                    className="h-full w-full object-cover md-1:max-w-350px lg:max-w-450px xl:max-w-650px 3xl:max-w-890px md-1:mr-0 md-1:ml-auto max-h-866px"
                    src={ImageReview}/>
            </div>
        </div>
    );
};

export default Review;
