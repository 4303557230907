import React from 'react';
import { SectionHeadingComponent } from '@tradesorg/component-library-waas-templates.template-3-section-heading';
import { ServiceImage } from '@tradesorg/component-library-waas-templates.template-3-service-image';
import Markdown from 'markdown-to-jsx';
import ServiceImg1 from '@assets/images/template-3/service01.png';
import ServiceImg2 from '@assets/images/template-3/service02.png';
import ServiceImg3 from '@assets/images/template-3/service03.png';
import ServiceImg4 from '@assets/images/template-3/service04.png';
import ServiceImg5 from '@assets/images/template-3/service05.png';


const Services = ({ data }) => {
    const { slogan, heading, services } = data
    
    const imageHandler = (index) => {

        switch (index%6) {
            case 0:
                return ServiceImg1
            case 1:
                return ServiceImg2
            case 2:
                return ServiceImg3
            case 3:
                return ServiceImg2
            case 4:
                return ServiceImg4
            case 5:
                return ServiceImg5
        }
    }

    return (
        <div id="services" className={`servicesComponentWrapper justify-center items-center`}>
            <div className="servicesImgWrapper px-5 md-1:px-10 xl:px-0 pt-50px pb-50px md-1:py-20 md-1:col-span-2 xl:col-span-1 relative 
            xl:ml-98px xl:mr-98px xl:pt-200px border-b">
                <SectionHeadingComponent 
                    sloganText={slogan} 
                    heading={ <Markdown>{heading}</Markdown> } 
                    centered={true}
                    className={{
                        'slogan': 'xs:text-sm',
                        'heading': 'xs:font-24px lg:font-30px mt-19px mb-30px md-1:mb-10 xl:mb-42px',
                    }}
                />
                <div className={`serviceList grid grid-cols-1 md-1:grid-cols-2 2xl:grid-cols-3 gap-x-20px gap-y-20px mx-auto lg:max-w-2xl 2xl:max-w-6xl 3xl:max-w-none`}>
                    { services?.map((service, index) => {
                        return (
                            <ServiceImage 
                                key={index} 
                                title={service.title} 
                                content={service.text} 
                                img={ !!service?.photo[0] ? service.photo[0].url : imageHandler(index)} 
                                // btnText="CTA Button" 
                                classNames={{
                                    wrapper: 'mx-auto',
                                    'imgIcon': 'mt-69px mb-21px', 
                                    imgWrapper : 'rounded-4px', 
                                    contentWrapper: 'rounded-4px flex flex-col justify-center',

                                }}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Services;
