import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { SectionHeadingComponent } from '@tradesorg/component-library-waas-templates.template-3-section-heading';
import { Template3OurTeam } from '@tradesorg/component-library-waas-templates.template-3-our-team';

const OurTeam = ({ data }) => {
    const { slogan, heading, team } = data

    return (
        <div className="OurTeamComponentWrapper justify-center items-center bg-gray-50">
            <div className="servicesImgWrapper px-5 md-1:px-10 xl:px-0 py-10 md-1:py-20 md-1:col-span-2 xl:col-span-1 relative xl:mx-98px 3xl:mx-390px xl:py-200px border-b">
                <div className="max-w-7xl xl:mx-auto">
                    <SectionHeadingComponent
                        sloganText={slogan}
                        heading={ ReactHtmlParser(heading) }
                        centered={true}
                        className={{
                            'heading': 'mt-19px xs:font-24px md-1:font-30px md-1:mb-10 xl:mb-42px text-center mb-35px md-1:mb-0',
                            'slogan': 'xs:font-14px md-1:font-16px',
                        }}
                    />

                    <div className="md-1:flex mt-10px lg:mt-24px gird gird-cols-auto gap-20px mx-auto" style={{maxWidth: '1141px'}} >
                        {team && team[0] &&  team?.map((item, index) => {
                            return (
                                <div className="w-full mt-30px" key={index}>
                                    <Template3OurTeam
                                        imageUrl={ item?.headshot?.url ?? null}
                                        name={ item.name }
                                        description={ item.description }
                                        jobPosition={ item.title }
                                        className={{
                                            wrapper: 'm-auto md-1:ml-0 md-1:max-w-225px lg:max-w-310px xl:max-w-367px'
                                        }}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurTeam;
