import React, { useState } from 'react';
import { RequestQuoteForm } from '@tradesorg/component-library-waas-templates.template-3-request-quote-form';
import styles from './index.module.scss';
import { Formik } from 'formik';
import { requestQuote } from '../../../../utils/requestQuote';
import SuccessModal from '../../../../components/commons/successModal';
import IntroImg from '@assets/images/template-3/intro.jpg';


const Intro = ({ data }) => {
    const { title, subTitle, image ,usp} = data ;
    const [showModal, setShowModal] = useState(false);
    const [initialValues, setInitialValues] = useState({
        'name': '',
        'phone': '',
        'email' : ''
    });
    const [isLoading,setLoading] = useState(false)
     
    let errorMessage;
    return (
        <div className={`introComponentWrapper grid grid-cols-1 md-1:grid-cols-3 xl:grid-cols-2 justify-center items-center`}  >
            <div className="px-5 md-1:px-10 xl:px-0 pt-50px pb-60px md-1:py-20 md-1:col-span-2 xl:col-span-1 relative
            xl:pl-98px xl:pr-98px">
                <h2 className="mt-4 lg:text-xl text-blue xs:text-lg font-bold">{title}</h2>
                <div className={`font-38px mt-4 lg:font-50px w-full leading-9 font-extrabold
                 w-335px lg:w-505px  ${styles.introTitle}`}>
                    { usp?.option}
                </div>
                <div className="mt-22px text-gray-700 text-sm text-gray-700  w-full">
                    { subTitle }
                </div>
                <div className={`mt-22px hidden md-1:block`}>
                    <Formik initialValues={initialValues} onSubmit={async(values)=>{
                        if(!!values.email.length || !!values.phone.length){
                            setLoading(true)
                            const response = await requestQuote({name : values.name , phone_number : values.phone , email : values.email})
                            try {
                                if( response.status === "SUCCESS"){
                                    setShowModal(true)
                                    setLoading(false)
                                }
                            }
                            catch{
                                console.log(response)
                                setLoading(false)
                            }
                        }
                        else{
                            errorMessage = true;
                            
                        }
                    }}>
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                        }) => {
                            return (
                                <RequestQuoteForm
                                    btnText="Request quote"
                                    position="vertical"
                                    btnTemplate={3}
                                    values={values}
                                    background={false}
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}
                                    errorMessage={errorMessage ? 'Email or phone number is required' : null}
                                    isLoading={isLoading}
                                />
                            )
                        }
                    }
                </Formik>
                </div>
                <div className={`mt-6 block md-1:hidden z-10 -mx-5`}>
                    <div className="pt-4 px-5 lg:pt-38px lg:pb-38px">
                        <Formik initialValues={initialValues} onSubmit={async(values)=>{
                         if(!!values.email.length || !!values.phone.length){
                            setLoading(true)
                            const response = await requestQuote({name : values.name , phone_number : values.phone , email : values.email})
                            try {
                                if( response.status === "SUCCESS"){
                                    setShowModal(true)
                                    setLoading(false)
                                }
                            }
                            catch{
                                console.log(response)
                                setLoading(false)
                            }
                        }
                        else{
                            errorMessage = true;
                            
                        }
                    }}>
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                            }) => {
                                return (
                                    <RequestQuoteForm
                                        values={values}
                                        onSubmit={handleSubmit}
                                        onChange={handleChange}
                                        btnText="Request quote"
                                        position="vertical"
                                        btnTemplate={3}
                                        background={false}
                                        className={{
                                            wrapper: 'gap-10px lg:gap-4'
                                        }}
                                        errorMessage={errorMessage ? 'Email or phone number is required' : null}
                                        isLoading={isLoading}
                                    />
                                )
                            }
                        }
                        </Formik>
                    </div>
                </div>
            </div>
            {showModal && <SuccessModal setShowModal={setShowModal}/>} 
            <div className="block">
                <img className="h-375px w-full xl:h-full xl:w-full object-cover" src={ IntroImg }/>
            </div>
            
        </div>
    );
};
export default Intro;
