import React, { useState, useEffect } from 'react';
import { Template3FaqItem } from '@tradesorg/component-library-waas-templates.template-3-faq-item';
import {nanoid} from "nanoid";

const FaqItemList = ({keyword ="" , ...props}) => {
    const [items, setItems] = useState(props.items);
    const [faqState, setFaqState] = useState([]);

    useEffect(() => {
        setFaqState(items.map((item) => false));
    }, []);

    const handleState = (index, value) => {
        const prev = faqState;
        prev[index] = value;
        setFaqState([...prev]);
    };

    return (
        <div>
            { items?.filter(item=> item.question.toLowerCase().includes(keyword.toLowerCase())).slice(0,10).map((item, index) => {
                return (
                    <div className="w-full " key={nanoid()}>
                        <Template3FaqItem
                            className={{
                                title: 'my-auto',
                                content: 'text-gray-500 pb-20px xl:pb-26px'
                            }}
                            title={item.question}
                            
                            content={item.answer}
                            active={faqState[index]}
                            handleState={handleState}
                            index={index}
                        />
                    </div>
                );
            })}
        </div>
    );
};
export default FaqItemList;
