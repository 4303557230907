import React from 'react';
import { SectionHeadingComponent } from '@tradesorg/component-library-waas-templates.template-3-section-heading';
import { Progress } from '@tradesorg/component-library-waas-templates.template-3-progress';
import Markdown from 'markdown-to-jsx';
import "./index.scss";
import Process1 from '@assets/images/template-3/process1.png';
import Process2 from '@assets/images/template-3/process2.png';
import Process3 from '@assets/images/template-3/process3.png';
import Process4 from '@assets/images/template-3/process4.png';
import Process5 from '@assets/images/template-3/process5.png';


const OurProgress = ({ data }) => {
    const { slogan, heading, processes, image } = data

    const imageHandler = (index) => {

        switch (index%5) {
            case 0:
                return Process1
            case 1:
                return Process2
            case 2:
                return Process3
            case 3:
                return Process4
            case 4:
                return Process5
        }
    }

    return (
        <div id="our-process" className={`ProgressComponentWrapper justify-center items-center`}>
            <div className="servicesImgWrapper px-5 md-1:px-10 xl:px-0 py-10 md-1:py-20 md-1:col-span-2 xl:col-span-1 relative
            xl:ml-98px xl:mr-98px xl:pt-200px border-b xl:pb-200px">
                <SectionHeadingComponent
                    sloganText={slogan}
                    heading={ <Markdown>{heading}</Markdown> }
                    centered={true}
                    className={{
                        'heading': 'mt-15px lg:mt-19px xl:mb-10px xs:font-24px md-1:font-30px',
                        'slogan': 'xs:font-14px md-1:font-16px',
                    }}
                />

                {processes && processes[0] && <div className="list-progress">
                    { processes?.map((item, index) => {
                        return (
                            <div className="mt-40px lg:mt-50px" key={index}>
                                <Progress 
                                    title={item.title}
                                    content={ <Markdown>{item.longDescription ? item.longDescription : item.shortDescription ? item.shortDescription : ''}</Markdown> }
                                    imageUrl={imageHandler(index)}
                                    badgeText=''
                                    className={{
                                        rightWrapper: ``,
                                        contentWrapper: ` mx-auto xl:mx-0 md-1:pl-53px xl:pl-0 2xl:pl-53px 3xl:pl-0`,
                                        wrapper: `max-w-5xl mx-auto`,
                                        badge: `xs:mt-0`,
                                        displayLine: ``,
                                        title: ``,
                                        img: `lg:h-397px xs:max-w-527px md-1:absolute md-1:w-1/2`,
                                        content: `leading-8 `
                                    }}
                                />
                            </div>
                        );
                    })}
                </div>}

                
            </div>
        </div>
    );
};

export default OurProgress;