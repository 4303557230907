import React from 'react';
// import {Template3ServiceTile} from '@tradesorg/component-library-waas-templates.template-3-service-tile';
import {Template3BusinessFeature} from '@tradesorg/component-library-waas-templates.template-3-business-feature';
import "./index.scss";
import BusinessFeatures from '@assets/images/template-3/template_3_about_us.png';

const AboutUs = ({ data }) => {
    const { title, businessFeatures, description, image } = data;

    // const aboutUsData = {
    //     inverted: false,
    //     subTitle: "",
    //     title: title,
    //     ctaText: "",
    //     content: description,
    //     image: image?.src,
    //     className: {
    //         wrapper: `lg:h-auto `,
    //         title: `text-2xl font-semibold lg:font-30px font-extrabold leading-normal`,
    //         content: `mt-23px xl:mt-14px xl:mt-22px font-16px lg:my-6`,
    //         img: `xl:max-w-511px lg:max-w-450px w-full bg-no-repeat bg-cover lg:absolute left-0 bottom-0 top-0 justify-center lg:w-1/2 image`,
    //         fakeImage: `w-full lg:w-1/2 `,
    //         rightWrapper: `flex w-full mx-auto px-4 flex-wrap max-w-lg lg:max-w-7xl mx-auto`,
    //         contentWrapper: `w-full lg:w-1/2 flex flex-col  justify-center mx-auto xl:mx-0 text-center lg:text-left 2xl:h-auto  lg:mt-0 2xl:pl-58px 3xl:pl-0`
    //     }
    // };

    return (
        <div className={`servicesImgWrapper px-5 md-1:px-10 xl:px-0 py-10 md-1:py-20 md-1:col-span-2 xl:col-span-1
         relative xl:ml-98px xl:mr-98px xl:pt-200px border-b`}>
            <div className="relative max-w-lg lg:max-w-7xl mx-auto lg:flex ">
                <div className="lg:w-1/2">
                    <img src={BusinessFeatures} alt="Business Amenities" className="lg:w-1/2 lg:ml-auto lg:mr-16" />      
                </div>
                       
                <div className="lg:w-1/2">
                    {/* <p className="max-w-lg leading-loose opacity-50">It's difficult to find examples of lorem ipsum in use before Letraset made it popular as a dummy text in the 1960s, although McClintock says he remembers coming across the lorem ipsum passage in a book of old metal type samples. So far he hasn't relocated where he once saw the passage.</p> */}
                    <div className="font-extrabold leading-7 mt-29px mb-29px xl:mt-0 font-24px">Our <span className="text-blue-500">business</span> features:</div>
                    <Template3BusinessFeature 
                            className={{
                            wrapper: 'flex w-full relative flex-wrap',
                            icon: 'mr-19px w-19px h-16px',
                            item: 'w-full lg:w-1/2 mb-20px leading-loose flex items-center'
                        }}
                        iconColor={'#3287eb'} 
                        items={businessFeatures}
                    />
                </div>
                    
                
                {/* <div className="lg:flex w-full mt-40px xl:mt-150px">
                    <div className="w-full lg:w-1/2 xl:max-w-511px text-center aboutUSNote">
                        TX Plumbing License #41808240
                    </div>
                    <div className="w-full lg:w-1/2 mt-18px text-center lg:text-left lg:mt-0 aboutUSNote">
                        Registered with the Plumbing Specialists Chapter 42
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default AboutUs;
