import React, { useEffect, useState } from 'react';
import data from '@data/template-3/homepage.json';
import { graphql } from 'gatsby';
import Layout from '@components/commons/template-3/layout';
import Intro from '@containers/template-3/homepage/intro';
import Services from '@containers/template-3/homepage/services';
// import TradeCategory from '@containers/template-3/homepage/trade-category';
import OurProcess from '@containers/template-3/homepage/our-process';
// import Guarantees from '@containers/template-3/homepage/guarantees';
import BusinessFeatures from '@containers/template-3/homepage/business-features';
import OurTeam from '@containers/template-3/homepage/our-team';
// import PhotoGallery from '@containers/template-3/homepage/photo-gallery';
import SocialLinks from '@containers/template-3/homepage/social-links';
// import Subscribe from '@containers/template-3/homepage/subscribe';
import Review from '@containers/template-3/homepage/review';
import Faq from '@containers/template-3/homepage/faq';
import OurProgress  from '../../containers/template-3/homepage/our-process'
import { getRandomFaqs } from '../../utils/getRandomFaqs';
import { getRandomUsp } from '../../utils/getRandomUsp';

const Homepage = ({ 
    data: {
        allStrapiWebsites: { nodes },
        allStrapiFaqs,
        allStrapiUniqueSellingPropositions
    },
}) => {
    const [usp,setUsp] = useState(null)
    useEffect(()=>{
        setUsp(getRandomUsp(allStrapiUniqueSellingPropositions.nodes))
    },[])
    const businessData = nodes[0].business;
    
    const businessLayoutData = {
        logo: businessData.logoImage?.localFile?.childCloudinaryAsset?.fluid ?? null,
        phone: businessData.phone,
        email: nodes[0].trade_pro.email,
        singleLineAddress: businessData.address?.singleLineAddress,
        socialProfiles: businessData.socialProfiles,
        ...businessData
    };

    const seoData = {
        title : businessData.displayName,
        description : businessData.shortDescription,
        domain : nodes[0].websiteDomain.name,
        logo : businessData.logoImage?.url ?? null,
        previewImage : businessData.coverImage?.url ?? null
    };

    const introData = {
        usp,
        title: businessData.displayName,
        subTitle: businessData.shortDescription,
        image: businessData.coverImage?.localFile?.childCloudinaryAsset?.fluid ?? null,
    }

    const contactUsData = {
        // ...data.template_2.area,
        phone: businessData.phone,
        email: nodes[0].trade_pro.email,
        radius: businessData.radiusServed,
        address : businessData.address,
        socialProfiles: businessData.socialProfiles,
    };

    const servicesData = {
        ...data.services,
        services: businessData.highlightedServices
    }

    // const tradeCategoryData = {
    //     ...data.tradeCategory
    // }

    // const photoGalleryData = {
    //     ...data.photoGallery
    // }

    const ourProcessData = {
        ...data.ourProcess,
        processes: businessData.process ? businessData.process: [],
     
    }

    // const guaranteesData = {
    //     ...data.guarantees
    // }

    const aboutUsData = {
        title: `About ${businessData.displayName}`,
        description: businessData.longDescription,
        businessFeatures: businessData.businessFeatures,
        
    }

    const ourTeamData = {
        ...data.ourTeam,
        team: businessData.team
    }

    const reviewsData = {
        ...data.reviews,
        feedbacks: businessData.feedbacks
    }

    const faqData = {
        ...data.faq,
        faqs: getRandomFaqs(allStrapiFaqs.nodes) 
    }

    // const subscribeData = {
    //     ...data.subscribe
    // }

 
    

    return (
        <Layout  businessData={ businessLayoutData } seoData={ seoData } faqs={faqData}>
            <Intro data={ introData } />
            {!!servicesData.services.length && <Services data={servicesData} />}
           
            {!!ourProcessData.processes.length &&   <OurProgress  data={ ourProcessData } />}

           
        
            <BusinessFeatures data={ aboutUsData } />
            {!!ourTeamData.team.length &&  <OurTeam data={ ourTeamData } />}
           {!!reviewsData.feedbacks.length && <Review data={ reviewsData } />}
            {!!faqData.faqs.length &&  <Faq data={ faqData } />}
           
            <SocialLinks data={ contactUsData } />
            {/* <Subscribe data={ subscribeData } /> */}
        </Layout>
    )
};
export const query = graphql`
    query($strapiId: Int!) {
        allStrapiWebsites(filter: { strapiId: { eq: $strapiId } }) {
            nodes {
                websiteDomain {
                    name
                  }
                trade_pro {
                    email
                }
                business {
                    logoImage {
                        url
                        localFile {
                            childCloudinaryAsset {
                                fluid(maxWidth: 200) {
                                    ...CloudinaryAssetFluid
                                }
                            }
                        }
                    }
                    phone
                    socialProfiles {
                        id
                        name
                        url
                    }
                    displayName
                    shortDescription
                    longDescription
                    businessFeatures {
                        name
                    }
                    coverImage {
                        url
                        localFile {
                            childCloudinaryAsset {
                                fluid(maxWidth: 1100) {
                                    ...CloudinaryAssetFluid
                                }
                            }
                        }
                    }
                    highlightedServices {
                        text
                        title
                        photo {
                            url
                            localFile {
                                childCloudinaryAsset {
                                    fluid(maxWidth: 1400) {
                                        ...CloudinaryAssetFluid
                                    }
                                }
                            }
                        }
                    }
                    images {
                        url
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 2000) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    process {
                        longDescription
                        shortDescription
                        title
                    }
                    feedbacks {
                        review
                        fullName
                        img {
                            url
                        }
                    }
                    radiusServed
                    address {
                        singleLineAddress
                        longitude
                        latitude
                    }
                    team {
                        name
                        title
                        description
                        headshot {
                            url
                        }
                    }
                }
            }
        }
        allStrapiFaqs{
            nodes {
              websites {
                id
              }
              question
              answer
            }
          }
          allStrapiUniqueSellingPropositions(filter: {websites: {elemMatch: {id: {eq: $strapiId}}}}) {
            nodes {
              usp_options {
                option
                id
              }
            }
          }
    }
`;

export default Homepage;
